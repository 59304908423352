import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';

/*

TODO:



React Router



Ryd op i kode (fjern console.log, opdater README.md, etc.)



Learning points:
[FromServices]
Dapper
signalR
Custom authentication
Data Protection Keys
MySql connection
MaterialUI
ASP.NET BackgroundService

*/

if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}