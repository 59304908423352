import React from 'react';
import './App.css';
import axios, { AxiosError } from 'axios';
import { Grid, TextField, Button, Card, CardContent, FormControl, InputLabel, Select, MenuItem, IconButton, InputAdornment, Popover, Typography, Box, Backdrop, CircularProgress } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

interface Props {
    enterRoom: (roomId: string) => void;
}

interface State {
    roomId: string,
    roomIdErrorText: string,
    cards: string,
    cardValues: string,
    cardValuesErrorText: string,
    isLoading: boolean,
    error: string | undefined,
}

class RoomSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            roomId: process.env.NODE_ENV === 'production' ? '' : 'YQTBG',
            cards: '0, 1, 2, 3, 5, 8, 13, 20, 40, 100, 200, ?',
            cardValues: '0, 1, 2, 3, 5, 8, 13, 20, 40, 100, 200, ?',
            cardValuesErrorText: '',
            roomIdErrorText: '',
            isLoading: false,
            error: undefined,
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(window.location.search);
        const room = query.get('room');
        if (room) {
            this.setState({ roomId: room }, () => {
                this.joinRoom();
            });
        }
    }

    createRoom = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        let cardValues = this.state.cardValues.split(/[\s,]+/i).filter(x => x.length > 0);
        cardValues = Array.from(new Set(cardValues)); // Remove duplicates
        if (cardValues.length < 2) {
            this.setState({ cardValuesErrorText: 'You must enter at least two cards' });
            return;
        }
        if (cardValues.length > 20) {
            this.setState({ cardValuesErrorText: 'You cannot have more than 20 cards' });
            return;
        }
        for (let card of cardValues) {
            if (card.length > 4) {
                this.setState({ cardValuesErrorText: 'A card can have maximum 4 characters' });
                return;
            }
            if (/[^a-z0-9?]/i.test(card)) {
                this.setState({ cardValuesErrorText: 'Only A-Z, numbers and a question mark are allowed' });
                return;
            }
        }

        this.setState({ isLoading: true });
        try {
            const data = new FormData();
            data.set('cardValues', this.state.cardValues);
            const result = await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/create', data, { withCredentials: true });
            console.log('create room', result);
            this.setState({ roomId: result.data.roomId }, () => {
                this.joinRoom();
            });
        } catch (error) {
            this.setState({ error: 'Connection failed. Try reloading the page.' });
        }
    }

    handleRoomIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ roomId: event.target.value, roomIdErrorText: '' });
    }

    joinRoom = async (event?: React.FormEvent<HTMLFormElement>) => {
        event?.preventDefault();

        const roomId = this.state.roomId; // Since this is an async function, the state may change amidst the function.
        if (!/^[a-z]{5}$/i.test(roomId)) {
            this.setState({ roomIdErrorText: 'Invalid room' });
            return;
        }

        this.setState({ isLoading: true });
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/' + roomId + '/join', null, { withCredentials: true });
            console.log('join room', result);
            this.props.enterRoom(roomId);
        } catch (error) {
            if (error.response) {
                const axiosError = error as AxiosError;
                if (axiosError.response!.status === 404) {
                    this.setState({ isLoading: false, roomIdErrorText: 'No such room' });
                    return;
                }
            }
            this.setState({ error: 'Connection failed. Try reloading the page.' });
        }
    }

    handleCardsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const cards = (event.target.value as string);
        this.setState({ cards: cards });
        if (cards !== 'Custom') {
            this.setState({ cardValues: cards });
        }
    }

    handleCustomCardsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({ cardValues: event.target.value as string, cardValuesErrorText: '' });
    }

    render() {
        return (
            <div style={{ top: 0, left: 40, right: 40, bottom: 0, position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Backdrop open={this.state.isLoading} style={{ color: 'white', zIndex: 10 }}>
                    {this.state.error ?
                        <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>{this.state.error}</span>
                        :
                        <CircularProgress style={{ width: 100, height: 100 }} color="inherit" />
                    }
                </Backdrop>
                <Grid container spacing={10} alignItems="center" justify="center" direction="row" >
                    <Grid item>
                        <form onSubmit={this.createRoom}>
                            <Card elevation={8}>
                                <CardContent style={{ width: 350, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <FormControl>
                                            <InputLabel id="cards-label">Card deck</InputLabel>
                                            <Select
                                                labelId="cards-label"
                                                value={this.state.cards}
                                                onChange={this.handleCardsChange}
                                                style={{ minWidth: 150 }}
                                            >
                                                <MenuItem value="0, 1, 2, 3, 5, 8, 13, 20, 40, 100, 200, ?">Numbers</MenuItem>
                                                <MenuItem value="XS, S, M, L, XL, XXL, ?">T-Shirt sizes</MenuItem>
                                                <MenuItem value="Custom">Custom</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <div style={{ height: 10 }} />
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {popupState => (
                                                <TextField
                                                    label="Card values"
                                                    value={this.state.cardValues}
                                                    onChange={this.handleCustomCardsChange}
                                                    variant="filled"
                                                    fullWidth
                                                    disabled={this.state.cards !== 'Custom'}
                                                    helperText={this.state.cardValuesErrorText}
                                                    error={!!this.state.cardValuesErrorText}
                                                    InputProps={{
                                                        endAdornment: this.state.cards !== 'Custom' ? undefined :
                                                            <InputAdornment position="end">
                                                                <IconButton size="small" {...bindTrigger(popupState)}>
                                                                    <InfoOutlinedIcon fontSize="inherit" />
                                                                </IconButton>
                                                                <Popover
                                                                    {...bindPopover(popupState)}
                                                                    anchorOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'right',
                                                                    }}
                                                                    transformOrigin={{
                                                                        vertical: 'center',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Box p={2}>
                                                                        <Typography>List all card values separated by commas, like this:</Typography>
                                                                        <Typography variant="overline">0, 1, 2, 3, 5, 8, 13, 20, 40, 100, 200, ?</Typography>
                                                                        <Typography>Each value can only consist of numbers and letters.</Typography>
                                                                    </Box>
                                                                </Popover>
                                                            </InputAdornment>,
                                                    }}
                                                />
                                            )}
                                        </PopupState>
                                        <div style={{ height: 20 }} />
                                        <Button type="submit" color="primary" variant="contained">Create new room</Button>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </form>
                    </Grid>
                    <Grid item>
                        <form onSubmit={this.joinRoom}>
                            <Card elevation={8}>
                                <CardContent style={{ width: 350, height: 200, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                                    <TextField label="Room ID" value={this.state.roomId} onChange={this.handleRoomIdChange} style={{ width: 100 }} helperText={this.state.roomIdErrorText} error={!!this.state.roomIdErrorText} />
                                    <div style={{ width: 15 }} />
                                    <Button type="submit" color="primary" variant="contained">Join</Button>
                                </CardContent>
                            </Card>
                        </form>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default RoomSelector;
