import React from 'react';
import { Card, CardContent, TextField, Button, CircularProgress } from '@material-ui/core';

interface Props {
    isLoading: boolean,
    setUsername: (username: string) => void;
    initialName: string;
}

interface State {
    inputUsername: string,
    nameError: string | undefined,
}

class NameSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inputUsername: this.props.initialName,
            nameError: undefined,
        };
    }

    handleUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ inputUsername: event.target.value, nameError: undefined });
    }

    setUsername = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        let username = this.state.inputUsername.trim();
        if (username.length === 0) {
            this.setState({ nameError: 'Please enter a username' });
            return;
        }

        this.props.setUsername(username);
    }

    render() {
        return (
            <form onSubmit={this.setUsername}>
                <Card elevation={8}>
                    <CardContent style={{ width: 400, height: 150, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                        <TextField label="Username" value={this.state.inputUsername} disabled={this.props.isLoading} onChange={this.handleUsernameInput} helperText={this.state.nameError} error={!!this.state.nameError} />
                        <div style={{ width: 10 }} />
                        <div style={{ position: 'relative' }}>
                            <Button type="submit" color="primary" variant="contained" disabled={this.props.isLoading}>Enter</Button>
                            {this.props.isLoading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
                        </div>
                    </CardContent>
                </Card>
            </form>
        );
    }
}

export default NameSelector;