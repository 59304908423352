import React from 'react';
import './App.css';
import axios from 'axios';
import { RoomDataHub, DataHubContext } from './room-data-hub';
import RoomSelector from './RoomSelector';
import Room from './Room';
import { CircularProgress, Backdrop } from '@material-ui/core';
import NameSelector from './NameSelector';



interface Props {
}

interface State {
  context: { dataHub: RoomDataHub },
  isLoading: boolean,
  username: string,
  roomId: string | undefined,
  userId: number | undefined,
  error: string | undefined,
  nameError: string | undefined,
  isLoadingName: boolean,
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      context: { dataHub: new RoomDataHub() },
      isLoading: true,
      username: '',
      roomId: undefined,
      userId: undefined,
      error: undefined,
      nameError: undefined,
      isLoadingName: false,
    };
  }


  async componentDidMount() {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + 'api/users/login-status', { withCredentials: true });
      if (result.data.isLoggedOn) {
        this.setState({ username: result.data.username, userId: result.data.userId });
      }

      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ error: 'Connection failed. Try reloading the page.' });
    }
  }

  setUsername = async (username: string) => {
    this.setState({ isLoadingName: true });

    try {
      const data = new FormData();
      data.set('username', username);
      const result = await axios.post(process.env.REACT_APP_API_URL + 'api/users/create', data, { withCredentials: true });
      this.setState({ username: result.data.username, userId: result.data.userId, isLoading: false });
    } catch (error) {
      this.setState({ isLoadingName: false, isLoading: true, error: 'Connection failed. Try reloading the page.' });
    }
  }

  enterRoom = (roomId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('room', roomId);
    window.history.replaceState(null, '', url.toString());

    this.setState({ roomId: roomId });
  }

  leaveRoom = () => {
    window.history.replaceState(null, '', './');
    this.setState({ roomId: undefined });
  }

  render() {
    return (
      <DataHubContext.Provider value={this.state.context}>
        {this.state.isLoading ?
          <Backdrop open={this.state.isLoading} style={{ color: 'white' }}>
            {this.state.error ?
              <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>{this.state.error}</span>
              :
              <CircularProgress style={{ width: 100, height: 100 }} color="inherit" />
            }
          </Backdrop>
          :
          this.state.userId ?
            (this.state.roomId ?
              <Room roomId={this.state.roomId} userId={this.state.userId} leaveRoom={this.leaveRoom} />
              :
              <RoomSelector enterRoom={this.enterRoom} />
            )
            :
            <div style={{ top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', zIndex: 1000, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <NameSelector isLoading={this.state.isLoadingName} setUsername={this.setUsername} initialName="" />
            </div>
        }
      </DataHubContext.Provider>
    );
  }
}

export default App;
