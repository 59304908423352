import React from 'react';
import * as signalR from '@microsoft/signalr';

export interface RoomState {
    adminUserId: number;
    cardsHidden: boolean;
    cardValues: string[];
    participants: ParticipantState[];
}

export interface ParticipantState {
    username: string;
    userId: number;
    card: string;
}

export class RoomDataHub {
    private connection?: signalR.HubConnection;

    public onConnectionClosed: (error?: Error) => void = () => {};
    public onConnectionReconnecting: (error?: Error) => void = () => {};
    public onConnectionReconnected: (connectionId?: string) => void = () => {};
    public onStateReceived: (roomId: string, state: RoomState) => void = () => {};

    public get connectionState() {
        return this.connection?.state;
    }

    public start = async () => {
        if (this.connection) {
            try {
                await this.connection.stop();
            } catch (error) { }
        }
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_API_URL + "room-data")
            .withAutomaticReconnect([0, 2000, 3000, 3000, 5000, 10000, 10000, 10000, 20000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 60000, 60000, 60000, 60000, 60000])
            .configureLogging(process.env.NODE_ENV === 'production' ? signalR.LogLevel.None : signalR.LogLevel.Information)
            .build();
        
        this.connection.onclose(this.onConnectionClosed);
        this.connection.onreconnecting(this.onConnectionReconnecting);
        this.connection.onreconnected(this.onConnectionReconnected);
        this.connection.on("StateReceived", this.onStateReceived);

        try {
            await this.connection.start();
            console.log("connected");
        } catch (error) {
            this.onConnectionClosed(error);
        }
    }

    public stop = async () => {
        await this.connection?.stop();
    }

    public sendMessage = async () => {
        await this.connection?.invoke("SendMessage", "User", "Hello everyone.. " + new Date());
    }

    public subscribeToRoom = async (roomId: string) => {
        await this.connection?.invoke("SubscribeToRoom", roomId);
    }
}

export const DataHubContext = React.createContext({
    dataHub: undefined! as RoomDataHub,
});