import React from 'react';
import { ParticipantState } from './room-data-hub';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Zoom, Modal } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import axios from 'axios';
import NameSelector from './NameSelector';

interface Props {
    participants: ParticipantState[];
    adminUserId: number;
    userId: number;

    removeParticipant: (userId: number) => void;
}

interface State {
    isSelectingName: boolean;
    isLoadingName: boolean;
}

class ParticipantList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isSelectingName: false,
            isLoadingName: false,
        };
    }

    removeParticipant = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const userId = parseInt(event.currentTarget.value);
        this.props.removeParticipant(userId);
    }

    showNameSelector = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.setState({ isSelectingName: true, isLoadingName: false });
    }

    handleCloseNameSelection = () => {
        this.setState({ isSelectingName: false });
    }

    setUsername = async (username: string) => {
        this.setState({ isLoadingName: true });

        try {
            const data = new FormData();
            data.set('username', username);
            const result = await axios.put(process.env.REACT_APP_API_URL + 'api/users/' + this.props.userId, data, { withCredentials: true });
            console.log('change username:', result);
            this.setState({ isSelectingName: false });
        } catch (error) {
            this.setState({ isLoadingName: false });
        }
    }

    render() {
        return (
            <>
                <TableContainer style={{ width: 300 }} component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="right">Person</TableCell>
                                <TableCell align="right" style={{ width: 50 }}>Pick</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.participants.map((participant) => (
                                <TableRow key={participant.userId}>
                                    <TableCell align="right" style={{ fontSize: 'larger' }}>
                                        {participant.userId === this.props.userId && <IconButton size="small" style={{ marginRight: 3, verticalAlign: 'text-bottom' }} onClick={this.showNameSelector}><EditOutlinedIcon color="disabled" fontSize="inherit" /></IconButton>}
                                        {this.props.adminUserId === this.props.userId && participant.userId !== this.props.userId && <IconButton size="small" style={{ marginRight: 3, verticalAlign: 'text-bottom' }} onClick={this.removeParticipant} value={participant.userId}><DeleteForeverIcon color="disabled" fontSize="inherit" /></IconButton>}
                                        {participant.username}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 'bold', fontSize: 'larger' }} align="right">
                                        <Zoom in={!!participant.card} timeout={{ appear: 0, enter: 150 }}>
                                            <div style={{ display: 'inline-block' }}>
                                                {participant.card === '*' ?
                                                    <CheckBoxIcon color="primary" fontSize="small" style={{ display: 'block' /* to prevent weird extra height */, float: 'right' }} />
                                                    :
                                                    participant.card
                                                }
                                            </div>
                                        </Zoom>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Modal
                    open={this.state.isSelectingName}
                    onClose={this.handleCloseNameSelection}
                    disableAutoFocus
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                    <div style={{ outline: 'none' }}>{/* This div is to fix "the modal content node does not accept focus", and remove the blue outline */}
                        <NameSelector isLoading={this.state.isLoadingName} setUsername={this.setUsername} initialName={this.props.participants.find(participant => participant.userId === this.props.userId)?.username || ''} />
                    </div>
                </Modal>
            </>
        );
    }
}

export default ParticipantList;