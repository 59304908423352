import React from 'react';
import { Grid, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import './CardPicker.css';

interface Props {
    cardWasPicked: (card: string | null) => void;
    pickedCard: string | null;
    allowedCards: string[];
    disabled: boolean;
}

interface State {
}

class CardPicker extends React.Component<Props, State> {
    async componentDidMount() {
    }

    pickCard = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        let card: string | null = event.currentTarget.value;
        if (this.props.pickedCard === card)
            card = null;
        this.props.cardWasPicked(card);
    }

    render() {
        return (
            <div>
                <Grid container spacing={2} alignItems="center" justify="center" direction="row">
                    {this.props.allowedCards.map(card =>
                        <Grid item key={card}>
                            <Card elevation={this.props.pickedCard === card ? 5 : 2} style={{ backgroundColor: this.props.pickedCard === card ? '#1976d2' : undefined, color: this.props.pickedCard === card ? 'white' : undefined }}>
                                <CardActionArea onClick={this.pickCard} value={card} disabled={this.props.disabled}>
                                    <CardContent>
                                        <Typography variant="h4" component="h2">
                                            {card}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

export default CardPicker;