import React from 'react';
import './App.css';
import axios, { AxiosError } from 'axios';
import { RoomDataHub, DataHubContext, RoomState, ParticipantState } from './room-data-hub';
import { HubConnectionState } from '@microsoft/signalr';
import CardPicker from './CardPicker';
import ParticipantList from './ParticipantList';
import { Button, CircularProgress, InputAdornment, IconButton, TextField, Typography, Fab, Backdrop } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ClipboardHelper from './clipboard';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

enum FlowStates {
    Loading,
    ConnectionLost,
    Estimating,
    Results,
}

interface Props {
    roomId: string;
    userId: number;
    leaveRoom: () => void;
}

interface State {
    flow: FlowStates;
    pickedCard: string | null;
    serverState: RoomState | undefined;
    roomUrl: string;
    error: string;
    observing: boolean;
}

class Room extends React.Component<Props, State> {
    private dataHub: RoomDataHub = undefined!;
    private mounted: boolean = false;

    constructor(props: Props) {
        super(props);

        const url = new URL(window.location.href.split('?')[0]);
        url.searchParams.set('room', this.props.roomId);

        this.state = {
            flow: FlowStates.Loading,
            pickedCard: null,
            serverState: undefined,
            roomUrl: url.toString(),
            error: 'Connection was lost. Check your internet connection and reload the page.',
            observing: false,
        };
    }

    async componentDidMount() {
        this.mounted = true;
        try {
            this.dataHub = this.context.dataHub;
            this.dataHub.onConnectionClosed = this.onConnectionClosed;
            this.dataHub.onConnectionReconnecting = this.onConnectionReconnecting;
            this.dataHub.onConnectionReconnected = this.onConnectionReconnected;
            this.dataHub.onStateReceived = this.onStateReceived;
            await this.dataHub.start();
            if (this.dataHub.connectionState === HubConnectionState.Connected) {
                this.performConnectActions();
            }
        } catch (error) {
            this.stopConnections();
        }

        const query = new URLSearchParams(window.location.search);
        const observer = query.get('observer') === 'true';
        if (observer)
            this.observe();
    }

    async componentWillUnmount() {
        this.mounted = false;
        this.stopConnections();
    }

    onConnectionClosed = (error?: Error) => {
        console.log('Connection closed :(');
        if (this.state.flow === FlowStates.ConnectionLost)
            return;
        if (this.mounted)
            this.setState({ flow: FlowStates.ConnectionLost });
    }

    onConnectionReconnecting = (error?: Error) => {
        console.log('Reconnecting...');
        if (this.state.flow === FlowStates.ConnectionLost)
            return;
        this.setState({ flow: FlowStates.Loading });
    }

    onConnectionReconnected = async () => {
        console.log('Reconnected!');
        if (this.state.flow === FlowStates.ConnectionLost)
            return;
        await this.performConnectActions();
    }

    performConnectActions = async () => {
        try {
            await this.dataHub.subscribeToRoom(this.props.roomId);
        } catch (error) {
            // If errors happen, RoomDataHub should be calling the Reconnecting/Closed callbacks
        }
    }

    onStateReceived = (roomId: string, state: RoomState) => {
        console.log('Received state for', roomId, state);

        const me = state.participants.find(participant => participant.userId === this.props.userId);
        if (!me && !this.state.observing) {
            this.observe();
        }

        if (state.cardsHidden === true) {
            this.setState({ flow: FlowStates.Estimating });

            if (this.state.pickedCard) {
                // If we think we have picked a card, let's check the server-side data to see if our pick has been reset.

                if (!me?.card) {
                    this.setState({ pickedCard: null });
                }
            }
        } else {
            this.setState({ flow: FlowStates.Results });
            Room.sortParticipants(state.participants);

            if (me?.card) {
                this.setState({ pickedCard: me.card });
            }
        }

        this.setState({ serverState: state });
    }

    private static sortParticipants(participants: ParticipantState[]) {
        function isNumeric(str: unknown) {
            if (typeof str !== "string") 
                return false;
            return !isNaN(str as any) && !isNaN(parseInt(str));
        }

        participants.sort((a, b) => {
            if (isNumeric(a.card) && isNumeric(b.card)) {
                const n1 = parseInt(a.card);
                const n2 = parseInt(b.card);
                if (n1 < n2)
                    return -1;
                else if (n1 > n2)
                    return 1;
                else
                    return 0;
            } else {
                return a.card.localeCompare(b.card);
            }
        });
    }

    restart = async () => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/restart', null, { withCredentials: true });
            console.log('restart:', result);
        } catch (error) {
            this.stopConnections();
        }
    }

    showCards = async () => {
        try {
            const result = await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/show-cards', null, { withCredentials: true });
            console.log('show cards:', result);
        } catch (error) {
            this.stopConnections();
        }
    }

    removeParticipant = async (userId: number) => {
        try {
            const result = await axios.delete(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/users/' + userId, { withCredentials: true });
            console.log('remove user:', result);
        } catch (error) {
            this.stopConnections();
        }
    }

    cardWasPicked = async (card: string | null) => {
        if (this.state.flow !== FlowStates.Estimating)
            return;

        this.setState({ pickedCard: card });

        try {
            const data = new FormData();
            if (card)
                data.set('card', card);
            const result = await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/pick-card', data, { withCredentials: true });
            console.log('pick card:', result);
        } catch (error) {
            if (error.response) {
                const axiosError = error as AxiosError;
                if (axiosError.response?.status === 403) {
                    // Probably just a timing issue. The cards just locked just as we were about to choose one.
                    return;
                }
            }
            this.stopConnections();
        }
    }

    copyLink = () => {
        ClipboardHelper.copyText(this.state.roomUrl);
    }

    navigateBack = async () => {
        this.props.leaveRoom();
    }

    observe = async () => {
        this.setState({ observing: true }, async () => {
            try {
                const result = await axios.delete(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/users/' + this.props.userId, { withCredentials: true });

                const url = new URL(window.location.href);
                url.searchParams.set('observer', 'true');
                window.history.replaceState(null, '', url.toString());
            } catch (error) {
                this.stopConnections();
            }
        });
    }

    join = async () => {
        try {
            await axios.post(process.env.REACT_APP_API_URL + 'api/rooms/' + this.props.roomId + '/join', null, { withCredentials: true });
            this.setState({ observing: false });

            const url = new URL(window.location.href);
            url.searchParams.delete('observer');
            window.history.replaceState(null, '', url.toString());
        } catch (error) {
            this.stopConnections();
        }
    }

    async stopConnections() {
        if (this.mounted) {
            this.setState({ flow: FlowStates.ConnectionLost });
        }

        try {
            if (this.dataHub.connectionState !== HubConnectionState.Disconnected)
                await this.dataHub.stop();
        } catch (error) {
        }
    }

    isAdmin = () => this.props.userId === this.state.serverState?.adminUserId;

    render() {
        return (
            <div>
                <Backdrop open={this.state.flow === FlowStates.Loading || this.state.flow === FlowStates.ConnectionLost} style={{ color: 'white', zIndex: 10 }}>
                    {this.state.flow === FlowStates.Loading &&
                        <CircularProgress style={{ width: 100, height: 100 }} color="inherit" />
                    }
                    {this.state.flow === FlowStates.ConnectionLost &&
                        <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>{this.state.error}</span>
                    }
                </Backdrop>
                {this.state.serverState &&
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 40 }}>
                        {!this.state.observing &&
                            <>
                                <CardPicker allowedCards={this.state.serverState.cardValues} pickedCard={this.state.pickedCard} cardWasPicked={this.cardWasPicked} disabled={this.state.flow !== FlowStates.Estimating} />
                                <div style={{ height: 40 }} />
                            </>
                        }
                        <Typography variant="h5">
                            {this.state.flow === FlowStates.Estimating ?
                                <span className="pulseText">Estimate now!</span>
                                :
                                'Results are in:'
                            }
                        </Typography>
                        <div style={{ height: 20 }} />
                        <ParticipantList participants={this.state.serverState.participants} removeParticipant={this.removeParticipant} adminUserId={this.state.serverState.adminUserId} userId={this.props.userId} />
                        <div style={{ height: 40 }} />
                        {this.isAdmin() &&
                            <div>
                                {this.state.flow === FlowStates.Estimating ?
                                    <Button color="secondary" variant="contained" onClick={this.showCards}>Show cards</Button>
                                    :
                                    <Button color="secondary" variant="contained" onClick={this.restart}>Restart</Button>
                                }
                                <div style={{ height: 40 }} />
                            </div>
                        }
                        <TextField
                            id="standard-adornment-password"
                            label="Copy to share"
                            type="text"
                            value={this.state.roomUrl}
                            variant="outlined"
                            disabled={true}
                            style={{ width: 400 }}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={this.copyLink}
                                        >
                                            <FileCopyIcon />
                                        </IconButton>
                                    </InputAdornment>,
                            }}
                            inputProps={{
                                style: {
                                    fontSize: "smaller",
                                    textAlign: "center",
                                },
                            }}

                        />
                    </div>
                }
                <div style={{ height: 60 }} />{/* Leave space for the FABs on small screens */}
                <Fab size="small" style={{ position: 'fixed', left: 10, bottom: 10 }} onClick={this.navigateBack}>
                    <ArrowBackIcon />
                </Fab>
                {this.state.observing ?
                    <Fab variant="extended" size="small" style={{ position: 'fixed', left: 65, bottom: 10, height: 40 }} onClick={this.join}>
                        <PersonAddIcon style={{ marginRight: 8 }} />
                        Join
                    </Fab>
                :
                    <Fab variant="extended" size="small" style={{ position: 'fixed', left: 65, bottom: 10, height: 40 }} onClick={this.observe}>
                        <VisibilityIcon style={{ marginRight: 8 }} />
                        Observe
                    </Fab>
                }
            </div>
        );
    }
}
Room.contextType = DataHubContext;

export default Room;
